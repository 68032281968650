import React from 'react';
import TutorialTemplate from '../../components/TutorialTemplate';

function Virtualization() {
  const virtComponents = [
    {
      virtComponent : 'kubevirt',
      compFunction : 'Manages kubernetes',
    },
    {
      virtComponent : 'virt-api',
      compFunction : 'Manages VMs and VM related workflows via API.',
    },
    {
      virtComponent : 'virt-launcher',
      compFunction : 'Manages the instantiation of a VMI.',
    },
    {
      virtComponent : 'virt-controller',
      compFunction : 'Manages the lifecyle of the pod hosting the VM.',
    },
    {
      virtComponent : 'Hyper Converge Operator',
      compFunction : 'Manages the CDI and virtualized components for OpenShift Virtualization.',
    },
    {
      virtComponent : 'Qemu Agent',
      compFunction : 'The QEMU (Quick Emulator) guest agent provides system information about the VM it is installed on. ',
    },
    {
      virtComponent : 'KVM',
      compFunction : 'Linux based Virtualization technology that enables guest VMs on host enviroments',
    },
    {
      virtComponent : 'Hostpath Provisioner Operator (HPP)',
      compFunction : 'Provisions storage for VMs',
    },
  ];
  const tabs = [
    {
      title: 'OpenShift Virtualization Overview',
      content: (
        <div>
          <p>Virtuallization in Red Hat OpenShift.</p>
          {/* Add more content */}
          <p>
            The most important thing to understand about virtualization on OpenShift is that it's not a native feature. 
            Virtualization capability is actually possible via an Operator. The OpenShift Virtualization Operator. Once installed, it uses a HyperConverge Operator to manage Virtual Machines. 
            Due to real life enterpise IT complexities, Red Hat OpenShift make managing Virtual Machines and containerized applications side-by-side a possibility within the same platform.
            This makes it easier for organizations that still rely on virtual machines for mission critical workloads that are also simultaeneously working with containers.
            Even better, Red Hat OpenShift makes some kubernetes features avaialble to managing virtual machines. Services and components such as routes, services, pods, 
            storage (volumes and volume claims), similar cli commands, the unified user interface (UI), yaml definition files, and user management. 
            All of this would be great for greenfield VM projects, but it's even better for brownfield projects because OpenShift Virtualization allows for importing existing VMs from outside the cluster 
            and also live migrating VMs between nodes in the cluster which is extremely valuable to workloads that cannot afford downtime. In other words, on the surface, OpenShift administrators can manage 
            virtual machines with the familiarity of managing containers while OpenShift virtualization handles the translation under the hood.
          </p>
          <h2>OpenShift Virtualization Components</h2>
          <p>Listed and the components that manage virtual machines and thier functions</p>

          <table className="min-w-full bg-white shadow-md rounded my-6">
        <thead>
          <tr>
            <th className="py-3 px-5 bg-gray-200 font-bold uppercase text-sm text-gray-600">Component</th>
            <th className="py-3 px-5 bg-gray-200 font-bold uppercase text-sm text-gray-600">Function</th>
            <th className="py-3 px-5 bg-gray-200"></th>
          </tr>
        </thead>
        <tbody>
          {virtComponents.map((item, index) => (
            <tr key={index} className="border-b">
              <td className="py-4 px-5 text-gray-700">
                {item.virtComponent}
              </td>
              <td className="py-4 px-5 text-gray-700">
                {item.compFunction}
              </td>
              <td className="py-4 px-5 text-gray-700 font-mono">
                <code></code>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
     <p>     
          Openshift Lfecylce manager
Operator Hub ; Red Hat | Supported| Comunity |Certified
Operators are containerized

Components: 
Cluster 
Namespace/Project

Features of Openshift Virtualization
************************************
Storage management
Containerized Data Importer (CDI)
Network management
Virtual machine consoles
Workloads management
Side-by-side and consistent management
Common infrastructure
Pipelines for VMs (CI/CD)
Management with K8s resources
Standalon VMIs


Virtualization Components:
**************************
virt-api
virt-controller
virt-hanlder
virt-launcher
libvirtd

Openshift virtualization operators and components:
***************************************
HyperConverged Operator (HCO)
KubeVirt: KubeVirt is the technology that allows virtual machines function as containers.
Hostpath Provisioner Operator(HPP)
Qemu agent
Kernel-based Virtual Machine (KVM)

## Underlying Kubernetes Architecture
**********************************
* Operator: cluster componets that simplifies managing another application of function
* Resource: Any of the conifgurable and consumable components managed by Openshift cluster 
* Control Plane: Container lifecycle management through API
* Data Plane: Provides storage ,networking and cpu to run nodes in the cluster 
* Pod: basic unit of deployment. Runs one or more containers that must be deployed on the same node, share netowrk ip, volumes and other resources 
* Container: executable image that defines the libraries and dependencies of an application

K8s operators: packaged k8s applications 

## Deploy OpenShift Virtualization Operator
****************************************
* How Red Hat OpenShift Virtualization components work
  * Allows users to manage VM and containerized based computing
  * Virtualization  supports RHEL 9
  * The Operator Lifecycle Manager must be enabled
* Deploying Red Hat OpenShift Virtualization Operator using Red Hat OpenShift Container Platform Operator Lifecycle Management




### Routes
Proides http, https, tls traffic to services in the cluster. 
Benefit: automatically assigns a DNS entry to your app when a route is created. 
    Note format: routename-namespace.default_domain.  If your default domain is apps.mycompany.com, and the route is named itranet and the project is prd? it will be intranet-prod.apps.mycompany.com

Data volume uses a PVC under the hood
Data volume is the boot source  
PVC is a requests for storage 
Virtuam Machine (VM) has the template to instantiate the Virtual machine instance
Under the hood of the VMI is the pod 
KVM + Libvirt+quemu = Virt launcher pod which runs the virtual machine 
If the virt launcher pod and vmi  are deleted, the vm template can rebuild the vm
In OpenShift, virtual machines run as pods 
The VM templates references the data volume which uses the PVC  the VM image has the boot source. 

Boot source -> PVC -> Data volume -> VM template -> VMI -> 
Virt Launcher Pod -> KVM + lib virtd + qemu 
All pods have this prefix: 
kubevirt.io/domain=

VMI represents the pod
VM is the template 
VM uses a data volume
Data volume represents a persistent volume claim

Pod name starts wuth virt-launcher
Virtual machine is the templates in which the VMI runs 
The VMI runs as a pod



OC

role
    -> targets (openshift resources)
      - > verbs (permissions)


Rolebinding is the linker between a user and a group

Cluster roles
    cluster admin
Namespace roles (admin)
admin is a namespace role, it's project specific
edit: create, modify and remove resources from a project/namespace. You can modify quotas or grant other users permission to your project. 
view: for audit purposes. No create, modify or remove reources from your project 

virt roles (specific to virtualization resources)
Kubevirt.io:admin 
kubevirt.io:edit
kubevirt.io:view

Openshift has a built-in monitoring framework based on prometheus. (It's a datastore based on scraping)
endpoint1
endpoint2
endpointn
Prometheus scrapes the endpoint metrics
- promQL (used by grafana)

Thanos querier
- deduplicates data in Prometheus


Grafana
- a visualization and dashboard tool 
- display data relevant to your project 
- use PromQL

Alert manager 
- proactive observability
- based on rules fired when evaluation is true longer than hold threshold. metric is hit


Networking
Node port binding

Route
mapping of an fqdn to a service

Edge termination:
TLS terminates at the edge

HTTPS between user and router pod
HTTP beween router pod and external load balancers

TLS is serverd by the router pod

Re-encrypted route:
2 TLS certs:
1 cert presented by the routed pod (trusted by the users browser, issued by a trusted CA)
1 cert by the application pod, issued by an internal CA (internal to the cluster)

when using oc command use --help to get more details about the command

Network
Network from external systems


Storage
PVC:
- size
- volumne 
- storage class
- access mode (RWO,RWX, ROX)

PVC is a namespace resource
- it's not the actual storage resource, but a storage request

StorageClass is a cluster resource
- Volume expansion: True or False
- Bind mode: when storage is created by storage provider
NFS is not supported


Networking in OpenShift
virt admin --> virtctl ssh api --> project -> vm -> vmi -> pod


Services, pods, config maps

Resources: pod, vm, vmi, pvc,

Templates
    Highly parameterized
    The VM creates a VMI which creates the pod 
    Can create the following:
        VM
        Data Volume 
        SVC 
        Route 
        Storage class 
        ConfigMap

Node Selector and Node Affinity: a preference

Taints and Tolerations: must match the prefence to run
Templates are yaml loaded into etcd in openshift


HA - Probe 
----------
Liveness probe
      livenessProbe:
        tcpSocket:
          port: 3306
        initialDelaySeconds: 10
        periodSeconds: 5


Requirements for live migration:
--------------------------------
Ports:
- 49152
- 49153
</p>
<p>Learning Objectives:
# Introduction to OpenShift  Virtualization
  - Describe the features and use cases of OpenShift Virtualization.
# Run and access Virtual Machines
Create, manage, inspect, and monitor virtual machines in Red Hat OpenShift Virtualization.
# Configure Kubernetes network for Virtual Machines
Configure standard Kubernetes network objects and external access for VMs and virtual machine-backed applications.
# Connect Virtual Machines to external networks
Configure node networking to connect virtual machines and nodes to networks outside the cluster.
Configure Kubernetes storage for Virtual Machines
Manage storage and disks for VMs in Red Hat OpenShift.
# Virtual Machine template management
Create and manage templates to provision virtual machines.
# Advanced Virtual Machine management
Import, export, snapshot, clone, and live migrate a virtual machine and initiate node maintenance.
# Configure Kubernetes high availability for Virtual Machines
Configure Kubernetes resources to implement high availability for virtual machines.
</p>
        </div>
      ),
    },
    {
      title: 'Prerequisites',
      content: (
        <div>
          <ul>
            <li>Access to an OpenShift cluster</li>
            <li>Installed OpenShift CLI (`oc`)</li>
            {/* Add more prerequisites */}
          </ul>
        </div>
      ),
    },
    {
      title: 'Steps',
      content: (
        <div>
          <h2>Step 1: Log in to OpenShift</h2>
          <p>Use the following command to log in:</p>
          <pre>
            <code>oc login --token=YOUR_TOKEN --server=YOUR_SERVER</code>
          </pre>
          {/* Add more steps */}
        </div>
      ),
    },
    {
      title: 'Conclusion',
      content: (
        <div>
          <p>You've successfully deployed an application on OpenShift!</p>
          {/* Add more conclusion content */}
        </div>
      ),
    },
  ];

  return (
    <TutorialTemplate title="Virtualization on Red Hat OpenShift" tabs={tabs} />
  );
}

export default Virtualization;